<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      width="45%">
    <el-form ref="form" :model="form" label-position="right" class="info" :rules="ruleCar">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="车牌号" :label-width="formLabelWidth" prop="carNum">
            <el-input v-model="form.carNum" autocomplete="off" placeholder="请输入车牌号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="公里数" :label-width="formLabelWidth" prop="kilometers">
            <el-input v-model="form.kilometers" autocomplete="off" placeholder="请输入公里数" maxlength="7"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系人" :label-width="formLabelWidth" prop="liaisonName">
            <el-select
                v-model="form.liaisonName"
                filterable
                allow-create
                @change="handleLiaisonName"
                default-first-option
                placeholder="请选择联系人">
              <el-option
                  v-for="item in cList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系电话" :label-width="formLabelWidth" prop="liaisonPhone">
            <el-input v-model="form.liaisonPhone" autocomplete="off" placeholder="请输入联系电话"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="押金" :label-width="formLabelWidth" prop="depositMoney">
            <el-input v-model="form.depositMoney" autocomplete="off" placeholder="请输入押金" maxlength="6">
              <span slot="append">元</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="租金" :label-width="formLabelWidth" prop="hireMoney">
            <el-input v-model="form.hireMoney" autocomplete="off" placeholder="请输入租金" maxlength="6">
              <span slot="append">元</span>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form ref="form" :model="form" label-position="right" :rules="ruleCar" class="s-radio">
      <el-row>
        <el-col :span="24">
          <el-form-item label="租期" :label-width="formLabelWidth" prop="hireTimeType">
            <el-radio-group v-model="form.hireTimeType">
              <el-radio label="三个月">三个月</el-radio>
              <el-radio label="半年">半年</el-radio>
              <el-radio label="一年">一年</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="出租方式" :label-width="formLabelWidth" prop="hireMode">
            <el-radio-group v-model="form.hireMode">
              <el-radio label="月租">月租</el-radio>
              <el-radio label="周租">周租</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
            <el-input
                type="textarea"
                rows="6"
                v-model="form.remark"
                maxlength="200"
                show-word-limit
                resize="none">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="handleSave">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {getCList} from "@/utils/api/contact";
import {getDict} from "@/utils/api/dict";
import {putUp} from "@/utils/api/car";

export default {
  name: "EditShelves",
  data() {
    return {
      dialogFormVisible: false,
      title: '新增',
      formLabelWidth: "80px",
      labelPosition: "right",
      pList: [],//平台列表
      cList: [],//联系人列表
      form: {
        cid: '',
        carNum: "",
        carBrand: '',//车辆品牌
        carMode: '',//车辆型号
        liaisonName: "",//联系人
        liaisonPhone: "",//联系电话
        kilometers: "",//公里数
        hireMoney: "",//租金
        depositMoney: "",//押金
        hireTimeType: "",//租期
        hireMode: "0",//出租方式
        remark: '', //备注
      },
      ruleCar: {
        carNum: [
          {required: true, message: '请填写车牌号', trigger: 'blur'},
        ],
        liaisonName: [
          {required: true, message: '请选择联系人', trigger: 'blur'},
        ],
        liaisonPhone: [
          {required: true, message: '请填写联系电话', trigger: 'blur'},
        ],
        kilometers: [
          {required: true, message: '请填写公里数', trigger: 'blur'},
        ],
        hireMoney: [
          {required: true, message: '请填写押金', trigger: 'blur'},
        ],
        depositMoney: [
          {required: true, message: '请填写租金', trigger: 'blur'},
        ],
        carServiceType: [
          {required: true, message: '请选择营运性质', trigger: 'blur'},
        ],
        hireTimeType: [
          {required: true, message: '请选择租期', trigger: 'blur'},
        ],
        hireMode: [
          {required: true, message: '请选择出租方式', trigger: 'blur'},
        ],

      }
    }
  },

  methods: {
    showEdit(row) {
      this.title = '上架车辆信息'
      this.form = Object.assign({}, this.form, row)
      this.dialogFormVisible = true
    },
    close() {
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form
      this.dialogFormVisible = false
      this.$emit("getList")
    },
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          // const { msg } = await doEdit(this.form)
          // this.$baseMessage(msg, 'success')
          // this.$refs['form'].resetFields()
          // this.dialogFormVisible = false
          this.$emit('save')
          this.form = this.$options.data().form
        } else {
          return false
        }
      })
    },
    //获取联系人
    getList() {
      // this.cList
      getCList().then(res => {
        if (res.code === 0) {
          this.cList = res.data
        }
      })
    },
    //获取运行平台
    getDict() {
      let data = {
        code: 'YXPT'
      }
      getDict(data).then(res => {
        if (res.code === 0) {
          this.pList = res.data
        }
      })
    },
    //选择联系人时绑定电话
    handleLiaisonName(value) {
      this.cList.map(item => {
        if (item.name === value) {
          this.form.liaisonPhone = item.telePhone
        }
      })
    },
    //车辆上架
    handleSave() {
      let data = this.form
      if(data.yearCheckUpFlag === "0"){
        data.yearCheckUpTimeScope = ''
      }
      if(data.insureFlag === "0"){
        data.insureTime = ''
      }
      putUp(data).then(res => {
        if (res.code === 0) {
          this.$message.success("上架成功")
          setTimeout(() => {
            this.close()
          }, 1000);

        } else {
          this.$message.error(res.msg)
        }
      })
    }
  },
  created() {
    this.getList()
    this.getDict()
  }
}
</script>

<style  lang="less" scoped>
.info {
  border-bottom: 1px solid #F1F1F1;

}

.el-dialog__body {
  .el-input {
    width: 80%;
  }
}

.el-select {
  width: 80%;
}

.s-radio {
  margin-top: 30px;
}
</style>
